import React, { useEffect, useState } from 'react'
import { CardContent, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { MarketSchedule } from '../../service/types/market-schedule'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { useMarketScheduleService } from '../../service/market-schedule-service'
import { useNotify } from 'react-admin'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'

function getDayName(dayOfWeek: string) {
    switch (dayOfWeek) {
        case 'MONDAY':
            return 'Pazartesi'
        case 'TUESDAY':
            return 'Salı'
        case 'WEDNESDAY':
            return 'Çarşamba'
        case 'THURSDAY':
            return 'Perşembe'
        case 'FRIDAY':
            return 'Cuma'
        case 'SATURDAY':
            return 'Cumartesi'
        case 'SUNDAY':
            return 'Pazar'
        default:
            return ''
    }
}

const hours = Array.from({ length: 24 }, (_, i) => i)
const minutes = Array.from({ length: 12 }, (_, i) => i * 5)

const MarketScheduleEditor = () => {
    const [schedule, setSchedule] = useState<MarketSchedule[]>([])
    const service = useMarketScheduleService()
    const [loading, setLoading] = useState(true)
    const notify = useNotify()
    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                setLoading(true)
                const schedule = await service.getAll()
                setSchedule(schedule)
            } catch (e) {
                notify('Çalışma saatleri yüklenirken bir hata oluştu.', { type: 'error' })
            } finally {
                setLoading(false)
            }
        }
        void fetchSchedule()
    }, [])

    const handleInputChange = (id: string, field: string, value: string | boolean | number) => {
        setSchedule(prevSchedule =>
            prevSchedule.map(day =>
                day.dayOfWeek === id ? { ...day, [field]: value } : day,
            ),
        )
    }

    const handleSave = async () => {
        try {
            setLoading(true)
            await service.update(schedule)
            notify('Çalışma saatleri başarıyla güncellendi.', { type: 'success' })
        } catch (e) {
            notify('Çalışma saatleri güncellenirken bir hata oluştu.', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack spacing={2}>
                    <Alert severity="info">Marketinizin çalışma saatlerini buradan düzenleyebilirsiniz.<br /><strong>Not:</strong> Eğer otomatik açma
                        ve
                        kapatma seçeneklerini işaretlerseniz, sistem girdiniz saat ve dakika da
                        otomatik olarak açilcak yada kapancak.
                        Eğer beni bilgilendir seçeneğini işaretlerseniz, sistem otomatik acilis ve kapanis da sizi bildirim gondercek.<br />
                        Düzenlemelerinizi yaptıktan sonra değişiklikleri kaydet butonuna basarak kaydedebilirsiniz.
                    </Alert>
                </Stack>
            </Grid>
            {schedule.map((day) => (
                <Grid item xs={12} md={6} lg={4} key={day.dayOfWeek}>
                    <Card sx={{ m: 1 }}>
                        <CardHeader title={getDayName(day.dayOfWeek)}
                                    action={
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={day.isClosed}
                                                    onChange={(e) => handleInputChange(day.dayOfWeek, 'isClosed', e.target.checked)}
                                                />
                                            }
                                            label="Tüm Gün Kapalıyız"
                                        />
                                    }
                        />
                        <Divider sx={{ mt: 1, mb: 2 }} />
                        <CardContent sx={{ opacity: day.isClosed ? 0.5 : 1, pointerEvents: day.isClosed ? 'none' : undefined }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Açılış Saati</InputLabel>
                                        <Select
                                            value={day.openHour}
                                            variant={'outlined'}
                                            onChange={(e) => handleInputChange(day.dayOfWeek, 'openHour', e.target.value)}
                                            label="Açılış Saati"
                                        >
                                            {hours.map(hour => (
                                                <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Açılış Dakikası</InputLabel>
                                        <Select
                                            value={day.openMinute}
                                            onChange={(e) => handleInputChange(day.dayOfWeek, 'openMinute', e.target.value)}
                                            label="Açılış Dakikası"
                                            variant={'outlined'}>
                                            {minutes.map(minute => (
                                                <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Kapanış Saati</InputLabel>
                                        <Select
                                            value={day.closeHour}
                                            variant={'outlined'}
                                            onChange={(e) => handleInputChange(day.dayOfWeek, 'closeHour', e.target.value)}
                                            label="Kapanış Saati"
                                        >
                                            {hours.map(hour => (
                                                <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Kapanış Dakikası</InputLabel>
                                        <Select
                                            value={day.closeMinute}
                                            variant={'outlined'}
                                            onChange={(e) => handleInputChange(day.dayOfWeek, 'closeMinute', e.target.value)}
                                            label="Kapanış Dakikası"
                                        >
                                            {minutes.map(minute => (
                                                <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={day.autoOpen}
                                                onChange={(e) => handleInputChange(day.dayOfWeek, 'autoOpen', e.target.checked)}
                                            />
                                        }
                                        label="Otomatik Aç"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={day.autoClose}
                                                onChange={(e) => handleInputChange(day.dayOfWeek, 'autoClose', e.target.checked)}
                                            />
                                        }
                                        label="Otomatik Kapat"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={day.notifyMe}
                                                onChange={(e) => handleInputChange(day.dayOfWeek, 'notifyMe', e.target.checked)}
                                            />
                                        }
                                        label="Beni Bilgilendir"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <LoadingButton variant="contained"
                                       onClick={handleSave}
                                       loading={loading}
                                       color="primary">Değişiklikleri Kaydet</LoadingButton>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default MarketScheduleEditor