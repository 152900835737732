import React, { useMemo } from 'react'
import { Translate, usePermissions, useTranslate } from 'react-admin'
import { DepartmentIcon } from '../../categories/Department'
import { CategoryIcon } from '../../categories/Category'
import { CityIcon } from '../../address/City'
import { DistrictIcon } from '../../address/District'
import { TownIcon } from '../../address/Town'
import { ProductIcon } from '../../features/product/list/product-list'
import { CampaignIcon } from '../../products/Campaign'
import EmailIcon from '@mui/icons-material/Email'
import { FaqIcon } from '../../features/faq/Faq'
import LocationIcon from '@mui/icons-material/LocationOn'
import GroceryIcon from '@mui/icons-material/LocalGroceryStore'
import OtherIcon from '@mui/icons-material/Settings'
import ReportIcon from '@mui/icons-material/Assessment'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { ToysRounded } from '@mui/icons-material'
import { DriverIcon } from '../../features/driver/driver'
import { OrderIcon } from '../../features/order/order'
import { PaymentIcon } from '../../features/order/payment/payment-list-view'

const categories = (translate: Translate) => [
    { title: translate('resources.departments.name'), path: '/departments', icon: <DepartmentIcon /> },
    { title: translate('resources.categories.name'), path: '/categories', icon: <CategoryIcon /> },
]
const locations = (translate: Translate) => [
    { title: translate('resources.cities.name'), path: '/cities', icon: <CityIcon /> },
    { title: translate('resources.districts.name'), path: '/districts', icon: <DistrictIcon /> },
    { title: translate('resources.towns.name'), path: '/towns', icon: <TownIcon /> },
]
const products = (translate: Translate) => [
    { title: translate('resources.products.name'), path: '/products', icon: <ProductIcon /> },
    { title: translate('resources.productUnits.name'), path: '/productUnits', icon: <ToysRounded /> },
    { title: translate('resources.campaigns.name'), path: '/campaigns', icon: <CampaignIcon /> },
    { title: translate('resources.orders.name'), path: '/orders', icon: <OrderIcon /> },
]
const reports = (translate: Translate) => [
    { title: translate('resources.payment-reference.name'), path: '/payment-reference', icon: <PaymentIcon /> },
    { title: translate('orderReport.name'), path: '/orderReport', icon: <OrderIcon /> },
]
const users = (translate: Translate) => [
    { title: translate('resources.drivers.name'), path: '/drivers', icon: <DriverIcon /> },
]

const others = (translate: Translate) => [
    { title: translate('resources.notificationEmails.name'), path: '/notificationEmails', icon: <EmailIcon /> },
    {
        title: translate('resources.faq.name'),
        path: '/faq',
        icon: <FaqIcon />,
    },
]


const adminItems = (translate: Translate) => [
    {
        subheader: 'Catalog',
        items: [
            {
                title: translate('menu.locations'),
                children: locations(translate),
                icon: <LocationIcon />,
                path: '/cities',
            },
            {
                title: translate('menu.departments'),
                path: '/departments',
                icon: <DepartmentIcon />,
                children: categories(translate),
            },
            {
                title: translate('menu.products'),
                path: '/products',
                icon: <GroceryIcon />,
                children: products(translate),
            },
        ],
    },
    {
        subheader: 'Management',
        items: [
            { children: users(translate), icon: <DriverIcon />, path: '/drivers', title: translate('menu.drivers') },
            {
                children: others(translate),
                icon: <OtherIcon />,
                path: '/notificationEmails',
                title: translate('menu.others'),
            },
            {
                children: reports(translate),
                icon: <ReportIcon />,
                path: '/orderReports',
                title: translate('menu.reports'),
            },
        ],
        key: 'users',
    },
]

export function useNavData() {
    const translate = useTranslate()
    const { permissions } = usePermissions()
    return useMemo(() => {
        let items = []
        if (permissions === 'ROLE_COMPANY') {
            items = adminItems(translate)
        } else if (permissions === 'ROLE_BRANCH') {
            items = adminItems(translate)
        } else {
            return []
        }

        return [
            {
                subheader: translate('ra.page.dashboard'),
                items: [
                    {
                        icon: <DashboardIcon />,
                        path: '/',
                        title: translate('ra.page.dashboard'),
                    },
                ],
            },
            ...items,
        ]
    }, [permissions])
}

export const MenuTranslations = {
    tr: {
        menu: {
            users: 'Kullanıcılar',
            products: 'Ürünler',
            reports: 'Raporlar',
            others: 'Diğer',
            locations: 'Bölgeler',
            departments: 'Departmanlar',
            drivers: 'Sürücüler',
        },
    },
}
