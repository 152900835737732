import React, { useEffect } from 'react'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import OurClient from './rest/OurClient'
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from './categories/Category'
import { DepartmentCreate, DepartmentEdit, DepartmentList, DepartmentShow } from './categories/Department'
import { CityList } from './address/City'
import { DistrictCreate, DistrictEdit, DistrictList } from './address/District'
import { TownCreate, TownEdit, TownList } from './address/Town'
import { ProductList, ProductShow } from './features/product/list/product-list'
import { CampaignCreate, CampaignEdit, CampaignList, CampaignShow } from './products/Campaign'
import Dashboard from './dashboard/dashboard'
import { NotificationEmailCreate, NotificationEmailEdit, NotificationEmailList } from './settings/NotificationEmail'
import { FaqCreate, FaqEdit, FaqList } from './features/faq/Faq'
import turkishMessages from './i18n/tr'
import DashboardLayout from './layout/dashboard'
import ThemeProvider from './theme'
import { MotionLazy } from './components/animate/motion-lazy'
import { SettingsDrawer, SettingsProvider } from './components/settings'
import { Route } from 'react-router-dom'
import { QueryClient } from 'react-query'
import { ProductUnitCreate, ProductUnitEdit, ProductUnitList } from './features/product/list/product-unit'
import { AccountView } from './features/profile/view'
import LoginView from './login/login-view'
import { DriverCreate, DriverEdit, DriverList, DriverShow } from './features/driver/driver'
import { useTheme } from '@mui/material/styles'
import { authProvider } from './login/auth-client'
import { OrdersList } from './features/order/order'
import { OrderDetailView } from './features/order/detail/order-detail-view'
import numeral from 'numeral'
import OrderReportListView from './features/report/order-report-list-view'
import PaymentListView from './features/order/payment/payment-list-view'
import ProductCreateView from './features/product/product-create-view'
import ProductEditView from './features/product/product-edit-view'
import CategoryRearrangeView from './features/category/category-rearrange-view'
import DepartmentRearrangeView from './features/department/departments-rearrange-view'
import ProductRearrangeView from './features/product/product-rearrange-view'
import { OrderEditView } from './features/order/edit/order-edit-view'
import { globalConfig } from './global-config'
import FaqRearrangeView from './features/faq/faq-rearrange-view'
import ProductFeatureCategoryEditView from './features/product/features/product-feature-category-edit-view'
import ProductFeatureCategoryView from './features/product/features/product-feature-category-view'
import ProductFeatureCategoryRearrangeView from './features/product/features/feature-rearrange-view'

const messages = {
    tr: { ...turkishMessages },
}
const i18nProvider = polyglotI18nProvider(() => messages['tr'], 'tr')
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: false,
            structuralSharing: false,
        },
        mutations: {
            retryDelay: 10000,
        },
    },
})

function App() {
    useEffect(() => {
        numeral.locale('tr')
    }, [])
    return (
        <SettingsProvider
            defaultSettings={{
                themeMode: 'light',
                themeDirection: 'ltr',
                themeContrast: 'default',
                themeLayout: 'vertical',
                themeColorPresets: 'default',
                themeStretch: false,
            }}
        >
            <ThemeProvider>
                <MotionLazy>
                    <SettingsDrawer />
                    <RaAdmin />
                </MotionLazy>
            </ThemeProvider>
        </SettingsProvider>
    )
}

const RaAdmin = () => {
    const theme = useTheme()
    return (
        <Admin
            dataProvider={OurClient(globalConfig.api.base)}
            authProvider={authProvider}
            loginPage={LoginView}
            dashboard={Dashboard}
            title={'Nerde Kaldı | Market Panel'}
            i18nProvider={i18nProvider}
            theme={theme}
            disableTelemetry
            queryClient={queryClient}
            layout={DashboardLayout}
        >
            <Resource
                name={'departments'}
                list={DepartmentList}
                edit={DepartmentEdit}
                show={DepartmentShow}
                create={DepartmentCreate}
            />,

            <Resource
                name={'campaigns'}
                list={CampaignList}
                edit={CampaignEdit}
                show={CampaignShow}
                create={CampaignCreate}
            />,
            <Resource name={'cities'} list={CityList} />,
            <Resource name={'districts'} list={DistrictList}
                      edit={DistrictEdit}
                      create={DistrictCreate} />,
            <Resource name={'towns'} list={TownList} edit={TownEdit}
                      create={TownCreate} />,
            <Resource name={'faq'} list={FaqList} edit={FaqEdit} create={FaqCreate} />,
            <Resource
                name={'notificationEmails'}
                list={NotificationEmailList}
                edit={NotificationEmailEdit}
                create={NotificationEmailCreate}
            />,
            <Resource
                name={'productUnits'}
                create={ProductUnitCreate}
                list={ProductUnitList}
                edit={ProductUnitEdit}
            />,
            <Resource name={'orders'} list={OrdersList} />,
            <Resource
                name={'categories'}
                list={CategoryList}
                edit={CategoryEdit}
                show={CategoryShow}
                create={CategoryCreate}
            />,
            <Resource
                list={ProductList}
                show={ProductShow}
                name={'products'}
            />,
            <Resource name={'drivers'} list={DriverList} edit={DriverEdit}
                      show={DriverShow}
                      create={DriverCreate} />,
            <Resource name={'remoteTowns'} />,
            <Resource name={'remoteDistricts'} />,
            <Resource name={'payment-reference'} list={PaymentListView} />
            <CustomRoutes>
                <Route path={'/orders/edit/:id'} element={<OrderEditView />} />,
                <Route path={'/profile'} element={<AccountView />} />,
                <Route path={'/orders/show/:id'} element={<OrderDetailView />} />,
                <Route path={'/orderReport'} element={<OrderReportListView />} />,
                <Route path={'/products/create'} element={<ProductCreateView />} />,
                <Route path={'/products/:id'} element={<ProductEditView />} />,
                <Route path={'/departments/rearrange'} element={<DepartmentRearrangeView />} />,
                <Route path={'/faq/rearrange'} element={<FaqRearrangeView />} />,
                <Route path={'/categories/rearrange/:id'} element={<CategoryRearrangeView />} />,
                <Route path={'/products/rearrange/:id'} element={<ProductRearrangeView />} />,
                <Route path={'/products/:id/features'} element={<ProductFeatureCategoryView />} />,
                <Route path={'/products/:id/features/new'} element={<ProductFeatureCategoryEditView />} />,
                <Route path={'/products/:id/features/:featureId'} element={<ProductFeatureCategoryEditView />} />,
                <Route path={'/products/:id/features/rearrange'} element={<ProductFeatureCategoryRearrangeView />} />,
            </CustomRoutes>,
        </Admin>
    )
}

export default App
